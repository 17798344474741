<template>
  <div id="favourite-view" class="mt-2">
    <p v-if="imageshow">
      <span class="d-flex justify-content-center"
        ><img
          class="img-fluid text-center"
          src="@/assets/site/images/Group6.png"
          alt=""
      /></span>
    </p>
    <div class="container-fluid" v-else>
      <div class="favorite-view-title-wrapper">
        <h1 class="display-1 title" style="font-weight: 400;">
          <img src="@/assets/site/images/gift-box-left.svg" alt="" />
          <span>Favorite products</span>
          <img src="@/assets/site/images/gift-box-right.svg" alt="" />
        </h1>
        <h6 class="lower-description">
          Your favorites items are saved to be purchased <br />
          later, this is NOT the same as your wishlist
        </h6>
      </div>
      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <div class="row">
            <div class="col-md-3" v-for="favouriteproduct in favouriteproducts">
              <div class="owl-item active wizard-box">
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="deletefavourite(favouriteproduct)"
                          class="fa fa-trash"
                          v-b-popover.hover.top="'Delete'"
                        ></b-button>
                      </div>
                      <a
                        href="#"
                        class="product photo product-item-photo"
                        tabindex="-1"
                      >
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px;"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%;"
                          >
                            <span
                              v-if="favouriteproduct.product_decrypt.imageurl"
                            >
                              <img
                                class="wizard-image-photo lazyload"
                                data-src=""
                                loading="lazy"
                                :src="favouriteproduct.product_decrypt.imageurl"
                                @error="
                                  () =>
                                    (favouriteproduct.product_decrypt.imageurl = null)
                                "
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                                @click="
                                  ViewProduct(favouriteproduct.product_decrypt)
                                "
                              />
                            </span>

                            <span v-else>
                              <img
                                class="product-image-photo lazyload"
                                src="@/assets/site/images/noimage.jpeg"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                            /></span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <!-- <a href="#" data-post="" @click="savetoFavourite(product)"
                            class="action towishlist btn-action link-wishlist" data-action="add-to-favourite"
                            title="Add to Favourite">
                            <span>Add to Favourite</span>
                          </a>
                         <a href="#" data-post="" @click="savetoWishlist(product)"
                            class="action tocart primary btn-action btn-cart" data-action="add-to-wishlist"
                            title="Add to Wish List">
                            <span>Add to Wish List</span>
                          </a>
                          <a href="#" class="action tocompare btn-action link-compare" @click="compareProduct(product)"
                            data-post="" title="Add to Compare">
                            <span>Add to Compare</span>
                          </a> -->

                        <a
                          href="#"
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click="ViewProduct(favouriteproduct.product_decrypt)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ favouriteproduct.product_decrypt.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper "
                            ><span class="price"
                              >{{
                                favouriteproduct.product_decrypt.price[
                                  "@currency"
                                ]
                              }}
                              {{
                                favouriteproduct.product_decrypt.price["#text"]
                              }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%;"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <single-product
        v-if="!productShow"
        v-bind:gender="gender"
      ></single-product>
    </div>
  </div>
</template>
<script>
export default {
  name: "WishlistView",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Discover your favorite products with our dedicated favorites view! Easily manage and view items you've saved for later purchase, ensuring you never miss out on that perfect gift. Simplify your shopping experience today!",
      },
      {
        name: "keywords",
        content:
          "favorite products, saved items, gift favorites, product management, online shopping, easy access, gift selection, product reviews, shopping tools, user-friendly interface, quick view options, product details, special gifts, shopping convenience, wishlist alternatives",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      favouriteproducts: [],
      imageshow: false,
    };
  },
  created() {
    this.getfavouriteProducts();
  },
  methods: {
    getfavouriteProducts() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/favourite/getfavouriteProducts";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.favouriteproducts = data.favproduct;
          if (this.favouriteproducts.length == 0) {
            this.imageshow = true;
          }
        });
    },

    deletefavourite(favouriteproduct) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/favourite/deleteProduct";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: favouriteproduct.product_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.$swal.fire("Good to Go", data.message, "success");
            this.getfavouriteProducts();
          } else {
            this.loader = false;
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    ViewProduct(favouriteproduct) {
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: {
          singleproduct: favouriteproduct,
          // allProducts_items: this.products,
          // allProducts_suggested_items: this.suggested_products ,
          // selected_friend: selected_friend,
          // clicked_wizarddata:this.wizard_data.wizard_details,
        },
      });
    },
  },
};
</script>
<style>
.favorite-view-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  gap: 12px;
  color: #c8730e;
}

.favorite-view-title-wrapper .title {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 0;
}

.favorite-view-title-wrapper .title img {
  width: 48px;
  flex-shrink: 0;
}

.favorite-view-title-wrapper .lower-description {
  font-size: 16px;
  padding: 8px;
  background: #dddddd;
  text-align: center;
  border-radius: 4px;
}
</style>
